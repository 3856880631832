import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getCourses, getCoursesPage ,getCourseById, getGeneralInfo} from "../../providers"

import { MainLayout } from "../../components/Layouts/MainLayout"
import { Hero2 } from "../../components/Hero2/index"
import ProgrammingCourses from "../../components/Programming/ProgrammingCourses"
import Brochure from "../../components/Programming/Brochure"
import { dateFormat } from "../../utils"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const CoursesAvailable = () => {
  const [coursesPages, setCoursesPages] = useState([])
  const [availableCourses, setAvailableCourses] = useState([])

  const [generalInfo, setGeneralInfo] = useState({})

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const _coursesPage = await getCoursesPage()
        setCoursesPages(_coursesPage[0])

        const _availableCourses = await getCourses({ is_available: true })
        
        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])



        
const datainput =[]
        _availableCourses.map((item)=>{
          for (let i=0; i <item.programs.length; i++ ){

            const program = item.programs[i];
            console.log("program:", program); 
            const isImmediateStart = 
            program?.starts_immediately === true || program?.starts_immediately === "true";
            console.log("Curso:", item.title, " | Programa:", program.title);
            console.log("isImmediateStart:", isImmediateStart); 

            const isEveryDay =
            program?.always_available === true;

            const aux = {
              init_date: isImmediateStart ? "Inicio inmediato" : dateFormat(program?.init_date),
              title: item.title,
              schedule: isEveryDay? "DISPONIBLE 24/7" : program?.schedule,
              days: isEveryDay? "DISPONIBLE 24/7" : program?.days,
              modality:program?.modality,
              instructor:{name:program?.instructor.name,cv:program?.instructor.cv},
              vacancies:program?.vacancies,
            }
            datainput.push(aux)
          }
        })
      
        setAvailableCourses(datainput)

  
      } catch (error) {
        console.log(error)
      }
    }
    fetchCourses()
  }, [])




  return (
    <>
      <MainLayout>
        <Container>
          <Hero2 data={coursesPages} />
          <ProgrammingCourses data={availableCourses} />
          <Brochure data={coursesPages?.brochure_image} background="celeste" contact={generalInfo} banner={coursesPages} />
        </Container>
      </MainLayout>
    </>
  )
}

export default CoursesAvailable
